import {createRouter, createWebHistory} from 'vue-router'
import WelcomeView from "@/views/WelcomeView";
import VenuePlanView from "@/views/VenuePlanView";
import ChargingNetworkView from "@/views/ChargingNetworkView";
import ReachView from "@/views/ReachView";
import ChargingView from "@/views/ChargingView";
import Vision7SView from "@/views/Vision7SView";
import SkodaAndYouView from "@/views/SkodaAndYouView";
import CooperationView from "@/views/CooperationView";
import SustainabilityView from "@/views/SustainabilityView";
import SelectedModelView from "@/views/SelectedModelView";
import CompletedView from "@/views/CompletedView";
import GameRulesView from "@/views/GameRulesView";
import GdprView from "@/views/GdprView";
// import AgreementsView from "@/views/AgreementsView.vue";

const routes = [
    {
        path: '/',
        name: 'welcome',
        component: WelcomeView
    },
    {
        path: '/souhlasy',
        name: 'agreements',
        // component: AgreementsView
        redirect: {name: 'welcome'}
    },
    {
        path: '/pravidla-souteze',
        name: 'gameRules',
        component: GameRulesView
    },
    {
        path: '/zpracovani-osobnich-udaju',
        name: 'gdpr',
        component: GdprView
    },
    {
        path: '/venue-plan',
        name: 'venue-plan',
        component: VenuePlanView,
        meta: {
            authenticated: true
        }
    },
    {
        path: '/nabijeci-sit',
        name: 'charging-network',
        component: ChargingNetworkView,
        meta: {
            authenticated: true
        }
    },
    {
        path: '/dojezd',
        name: 'reach',
        component: ReachView,
        meta: {
            authenticated: true
        }
    },
    {
        path: '/spoluprace',
        name: 'cooperation',
        component: CooperationView,
        meta: {
            authenticated: true
        }
    },
    {
        path: '/nabijeni',
        name: 'charging',
        component: ChargingView,
        meta: {
            authenticated: true
        }
    },
    {
        path: '/udrzitelnost',
        name: 'sustainability',
        component: SustainabilityView,
        meta: {
            authenticated: true
        }
    },
    {
        path: '/vision-7s',
        name: 'vision-7s',
        component: Vision7SView,
        meta: {
            authenticated: true
        }
    },
    {
        path: '/skoda-a-vy',
        name: 'skoda-and-you',
        component: SkodaAndYouView,
        meta: {
            authenticated: true
        }
    },
    {
        path: '/vybrany-model',
        name: 'selected-model',
        component: SelectedModelView,
        meta: {
            authenticated: true
        }
    },
    {
        path: '/a-je-to',
        name: 'completed',
        component: CompletedView,
        meta: {
            authenticated: true
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
