// import axios from "@/plugins/axios";

const defaultState = () => {
    return {
        canBeChargedByTime: null,
        canReachDistance: null,
        isTwoThousandPoints: null,
        completed: false
    };
};

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        canBeChargedByTime: (state) => state.canBeChargedByTime,
        canReachDistance: (state) => state.canReachDistance,
        isTwoThousandPoints: (state) => state.isTwoThousandPoints,
        completed: (state) => state.completed,
        allSet: (state) => state.canBeChargedByTime !== null
            && state.canReachDistance !== null
            && state.isTwoThousandPoints !== null
    },
    mutations: {
        SET_VALUE(state, {property, value}) {
            state[property] = value;
        },
        RESET(state) {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        setValue(context, {property, value}) {
            context.commit('SET_VALUE', {property, value})
        }
    },
};
