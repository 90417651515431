<template>
    <div class="selectedModels" :class="color">
        <div class="selectedModels__container">
            <h1 class="selectedModels__title">{{ name }}</h1>
            <img :src="image" class="selectedModels__car">
            <p class="selectedModels__subTitle">
                <strong>{{ model }}</strong>
            </p>
            <p class="selectedModels__perex mt-4">
                Váš vysněný vůz
            </p>
        </div>
        <div class="selectedModels__next">
            <c-button class="btn btn--borderWhite" :to="{name: 'completed'}">Dokončit</c-button>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import CButton from "@/components/CButton";
import nextStep from "@/mixins/nextStep";

export default {
    name: "SelectedModelView",
    components: {CButton},
    mixins: [nextStep],
    computed: {
        ...mapGetters('users', ['name']),
        ...mapGetters('skodaAndYou', ['person', 'color']),
        image() {
            const model =  this.person === 'businessman' ? 'enyaq-coupe-rs-iv' : 'enyaq-iv';
            return '/images/' + model + '/' + this.color + '.png'
        },
        model() {
            if (this.person === 'businessman') {
                return 'Škoda Enyaq Coupé RS iV';
            }
            return 'Škoda Enyaq iV';
        }
    }
}
</script>

<style scoped>

</style>
