<template>
    <div>
        <div v-if="page===0" class="coop">
            <header class="header text-white mb-5">
                <h1 class="h4">Spolupráce</h1>
            </header>
            <div class="textContainer">
                <div class="textContainer__heading">
                    Po kolikáté bude letos Škoda Auto hrdým sponzorem Tour de France?
                </div>
                <div class="coop__slider">
                    <simple-slider v-model="tourPartnerYears" :max="33" :readonly="completed"></simple-slider>
                </div>
                <svg class="ico" width="20" height="13"><use xlink:href="#bicycle"></use></svg>
            </div>

            <c-button :disabled="tourPartnerYears === 0" @click="popupOne = true" class="btn btn--green">
                Pokračovat
            </c-button>
            <answer-popup
                v-model="popupOne"
                :correct="tourPartnerYears === 20"
                @close="page += 1"
            >
                20 let
            </answer-popup>
        </div>
        <div v-else class="coop is-second">
            <header class="header text-white mb-5">
                <h1 class="h4">Spolupráce</h1>
            </header>
            <div class="textContainer">
                <div class="textContainer__heading">
                    Kolik kilometrů za čtyři měsíce ve službě najel každý z vozů Škoda Enyaq Coupé RS iV pro Coca-Cola?
                </div>
                <div class="coop__slider">
                    <simple-slider v-model="bankCarFleetCount" :max="50000" :step="1000" :readonly="completed"></simple-slider>
                </div>
                <svg class="ico" width="18" height="18"><use xlink:href="#cars"></use></svg>
            </div>
            <c-button :disabled="bankCarFleetCount === 0" @click="completePopup" class="btn btn--green">
                Pokračovat
            </c-button>
            <answer-popup
                v-model="popupTwo"
                :correct="bankCarFleetCount === 25000"
                @close="nextStep"
            >
                25000 km
            </answer-popup>
        </div>

    </div>
</template>

<script>
import SimpleSlider from "@/components/SimpleSlider";
import {mapGetters} from "vuex";
import stepValue from "@/utils/stepValue";
import CButton from "@/components/CButton";
import nextStep from "@/mixins/nextStep";
import AnswerPopup from "@/components/AnswerPopup";

const stepName = 'cooperation';

export default {
    name: "CooperationView",
    components: {AnswerPopup, CButton, SimpleSlider},
    mixins:[nextStep],
    data() {
        return {
            page: 0,
            correct: false,
            popupOne: false,
            popupTwo: false
        }
    },
    computed: {
        ...mapGetters('cooperation', ['allSet']),
        tourPartnerYears: stepValue(stepName, 'tourPartnerYears'),
        bankCarFleetCount: stepValue(stepName, 'bankCarFleetCount'),
        completed: stepValue(stepName, 'completed'),
    },
    methods: {
        completePopup() {
            this.completed = true;
            this.popupTwo = true;
        }
    },
    beforeRouteEnter(to, from, next) {
        return next((vm) => {
            vm.page = 0;
        })
    }
};
</script>

<style scoped>

</style>
