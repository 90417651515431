// import axios from "@/plugins/axios";

const defaultState = () => {
    return {
        season: '',
        drivingStyle: '',
        drivingEnvironment: '',
        completed: false
    };
};

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        season: (state) => state.season,
        drivingStyle: (state) => state.drivingStyle,
        drivingEnvironment: (state) => state.drivingEnvironment,
        temperature: (state) => state.temperature,
        completed: (state) => state.completed,
        allSet: (state) => state.season
            && state.drivingStyle
            && state.drivingEnvironment
    },
    mutations: {
        SET_VALUE(state, {property, value}) {
            state[property] = value;
        },
        RESET(state) {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        setValue(context, {property, value}) {
            context.commit('SET_VALUE', {property, value})
        }
    },
};
