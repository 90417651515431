<template>
    <div class="vision">
        <header class="header text-white">
            <h1 class="h4">Škoda Vision 7S</h1>
            <p class="perex">
                Poznejte nový designový jazyk
                a jedinečná technická řešení.
            </p>
        </header>

        <div class="">
            <div class="vision__car">
                <img :src="carFront" alt="Vision front">
                <c-button class="vision__btn vision__btn--1" :class="{'is-active': hasHighlight(1)}" @click="open(1)" >
                    <svg v-if="hasHighlight(1)" class="ico m-auto ico--big"><use xlink:href="#check"></use></svg>
                    <svg v-else class="ico m-auto ico--big"><use xlink:href="#add"></use></svg>
                </c-button>
                <c-button class="vision__btn vision__btn--2" :class="{'is-active': hasHighlight(2)}" @click="open(2)" >
                    <svg v-if="hasHighlight(2)" class="ico m-auto ico--big"><use xlink:href="#check"></use></svg>
                    <svg v-else class="ico m-auto ico--big"><use xlink:href="#add"></use></svg>
                </c-button>
                <c-button class="vision__btn vision__btn--7" :class="{'is-active': hasHighlight(7)}" @click="open(7)" >
                    <svg v-if="hasHighlight(7)" class="ico m-auto ico--big"><use xlink:href="#check"></use></svg>
                    <svg v-else class="ico m-auto ico--big"><use xlink:href="#add"></use></svg>
                </c-button>
                <c-button class="vision__btn vision__btn--3" :class="{'is-active': hasHighlight(3)}" @click="open(3)" >
                    <svg v-if="hasHighlight(3)" class="ico m-auto ico--big"><use xlink:href="#check"></use></svg>
                    <svg v-else class="ico m-auto ico--big"><use xlink:href="#add"></use></svg>
                </c-button>
            </div>

            <div class="vision__car">
                <img :src="carBack" alt="Vision back">
                <c-button class="vision__btn vision__btn--4" :class="{'is-active': hasHighlight(6)}" @click="open(6)" >
                    <svg v-if="hasHighlight(6)" class="ico m-auto ico--big"><use xlink:href="#check"></use></svg>
                    <svg v-else class="ico m-auto ico--big"><use xlink:href="#add"></use></svg>
                </c-button>
                <c-button class="vision__btn vision__btn--5" :class="{'is-active': hasHighlight(4)}" @click="open(4)" >
                    <svg v-if="hasHighlight(4)" class="ico m-auto ico--big"><use xlink:href="#check"></use></svg>
                    <svg v-else class="ico m-auto ico--big"><use xlink:href="#add"></use></svg>
                </c-button>
                 <c-button class="vision__btn vision__btn--6" :class="{'is-active': hasHighlight(5)}" @click="open(5)" >
                    <svg v-if="hasHighlight(5)" class="ico m-auto ico--big"><use xlink:href="#check"></use></svg>
                    <svg v-else class="ico m-auto ico--big"><use xlink:href="#add"></use></svg>
                </c-button>
            </div>
        </div>

        <div class="text-center">
            <c-button :disabled="!allSet" class="btn btn--green" @click="complete">
                Pokračovat
            </c-button>
        </div>

        <transition name="vision-fade">
            <div class="vision__popup" v-if="selectedHighlight">
                <c-button @click="opened = null;" class="vision__popupClose">
                    <svg class="ico mx-auto ico--medium mb-3"><use xlink:href="#close-white"></use></svg>
                    Zavřít
                </c-button>

                <div>
                    <img :src="selectedHighlight.image" class="vision__popupImg" alt="">
                    <div class="vision__popupContent">
                        <h2 class="vision__popupTitle">{{selectedHighlight.heading}}</h2>
                        <p class="vision__popupText">{{selectedHighlight.text}}</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import CButton from "@/components/CButton";
import stepValue from "@/utils/stepValue";
import nextStep from "@/mixins/nextStep";

import carFront from "@/assets/images/vision/vision-front.png";
import carBack from "@/assets/images/vision/vision-back.png";
import car1 from "@/assets/images/vision/face.jpg";
import car2 from "@/assets/images/vision/svetla.jpg";
import car3 from "@/assets/images/vision/linie.jpg";
import car4 from "@/assets/images/vision/zadni-svetla.jpg";
import car5 from "@/assets/images/vision/kola.jpg";
import car6 from "@/assets/images/vision/tazni.jpg";
import car7 from "@/assets/images/vision/bezpecnost.jpg";

export default {
    name: "Vision7SView",
    components: {CButton},
    mixins:[nextStep],
    data() {
        return {
            highlightsData: [
                {
                    id: 1,
                    image: car1,
                    heading: 'Tech-Deck Face s novým logem Škoda',
                    text: ''
                },
                {
                    id: 2,
                    image: car2,
                    heading: 'Přední světla ve tvaru písmene T',
                    text: ''
                },
                {
                    id: 3,
                    image: car3,
                    heading: 'Vysoce umístěná Tornado linie a mírně se svažující střecha zajišťují dobrou aerodynamiku',
                    text: ''
                },
                {
                    id: 4,
                    image: car4,
                    heading: 'Zadní světla ve tvaru písmene T',
                    text: ''
                },
                {
                    id: 5,
                    image: car5,
                    heading: '22” kola a robustní nárazníky, které jsou vyrobeny z odolných recyklovaných kusů starých pneumatik',
                    text: ''
                },
                {
                    id: 6,
                    image: car6,
                    heading: 'Oranžový háček slouží jako tažné oko, ale také zvenčí bezpečně přeruší ok vysokonapěťového proudu ve voze',
                    text: ''
                },
                {
                    id: 7,
                    image: car7,
                    heading: 'Bezpečnost a ochranu zajišťuje inovativní dětská autosedačka, která je umístěna proti směru jízdy ve středové konzole před druhou řadou sedadel.',
                    text: ''
                }
            ],
            opened: null,
            carFront: carFront,
            carBack: carBack,
        }
    },
    computed: {
        ...mapGetters('vision', ['highlights', "allSet"]),
        completed: stepValue('vision', 'completed'),
        selectedHighlight() {
            if(!this.opened) {
                return null;
            }
            return this.highlightsData.find((highlight) => highlight.id === this.opened)
        }
    },
    methods: {
        open(id) {
            this.$store.dispatch('vision/addHighlight', {id});
            this.opened = id;
        },
        hasHighlight(id) {
            return this.$store.getters['vision/hasHighlight'](id);
        },
        complete() {
            this.completed = true;
            this.nextStep();
        }
    },
}
</script>

<style scoped>

</style>
