import axios from "@/plugins/axios";

const defaultState = () => {
    return {
        authorization: null,
        sign: null
    };
};

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        isAuthorized: (state) => !!state.authorization,
        authorization: (state) => state.authorization,
        name: (state) => state.authorization ? state.authorization.name : '',
        isFilledSign: (state) => state.sign !== null,
        isGameEnabled: (state) => state.authorization ? state.authorization.gameEnabled : false,
    },
    mutations: {
        SET_AUTHORIZATION: (state, authorization) => {
            state.authorization = authorization;
        },
        SET_SIGN: (state, sign) => {
           state.sign = sign;
        },
        RESET(state) {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        async checkAuthorization(context) {
            try {
                const response = await axios.get('/users/authorization');
                context.commit('SET_AUTHORIZATION', response.data);
                return true;
            } catch (error) {
                return false;
            }
        },
        async complete(context) {
            await axios.post('/users/complete', {
                state: Object.fromEntries(Object.entries(context.rootState).filter(([key]) => key !== 'users'))
            })
        },
        async storeState(context) {
            await axios.post('/users/store-state', {
                state: Object.fromEntries(Object.entries(context.rootState).filter(([key]) => key !== 'users'))
            })
        },
        async signUser(context, {email, name, phone, gdpr, rules}) {
            try {
                const response = await axios.post('/users/sign', {
                    email,
                    name,
                    phone,
                    rules,
                    gdpr,
                    gdprLouda: false
                });
                context.commit('SET_AUTHORIZATION', response.data);
                localStorage.setItem('authToken', response.data.token)
                return null;
            } catch (error) {
                await context.dispatch('logout');
                return error.response.data;
            }
        },
        async signValidateUser(context, {email, name, phone}) {
            try {
                await axios.post('/users/sign-validate', {
                    email,
                    name,
                    phone,
                });
                context.commit('SET_SIGN', {email, name, phone});
                return null;
            } catch (error) {
                return error.response.data;
            }
        },
        async logout(context) {
            localStorage.removeItem('authToken')
            await context.dispatch('reset', null, {root: true});
        }
    },
};
