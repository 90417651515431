export default function (step, property) {
    return {
        get() {
            return this.$store.getters[step + '/' + property];
        },
        async set(value) {
            await this.$store.dispatch(step + '/setValue', {property, value});
        }
    }
}
