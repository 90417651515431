// import axios from "@/plugins/axios";
const defaultState = () => {
    return {
        chargeTime: 0,
        completed: false
    };
};

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        chargeTime: (state) => state.chargeTime,
        completed: (state) => state.completed,
        allSet: (state) => state.chargeTime > 0
    },
    mutations: {
        SET_VALUE(state, {property, value}) {
            state[property] = value;
        },
        RESET(state) {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        setValue(context, {property, value}) {
            context.commit('SET_VALUE', {property, value})
        },
    },
};
