// import axios from "@/plugins/axios";

const defaultState = () => {
    return {
        person: null,
        place: null,
        color: null,
        completed: false
    };
};

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        person: (state) => state.person,
        place: (state) => state.place,
        color: (state) => state.color,
        completed: (state) => state.completed,
        allSet: (state) => state.person && state.place && state.color
    },
    mutations: {
        SET_VALUE(state, {property, value}) {
            state[property] = value;
        },
        RESET(state) {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        setValue(context, {property, value}) {
            context.commit('SET_VALUE', {property, value})
        }
    },
};
