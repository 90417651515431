<template>
    <router-link
        :to="to"
        class="world__icon"
        :class="{'is-active' : active}"
    >
        <svg class="ico ico--checkmark">
            <use xlink:href="#ok"></use>
        </svg>
        <svg class="ico ico--checkworld">
            <use :xlink:href="`#${icon}`"></use>
        </svg>
    </router-link>
</template>

<script>
export default {
    name: "WorldIcon",
    props: {
        to: {
            type: Object
        },
        icon: {
            type: String
        },
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>
