<template>
    <div class="content" :style="{backgroundImage: 'url('+bgImg+')'}">
        <header class="header text-white mb-5">
            <h1 class="h4">Nabíjení</h1>
        </header>

        <div class="textContainer pb-0">
            <div class="textContainer__heading textContainer__p">
                Nastavte čas, který podle vás budete potřebovat pro nabití z 0 na 100 % baterie vozu Škoda Enyaq Coupé RS iV pomocí Škoda iV Charger Connect+ (11 kW).
            </div>
        </div>
        <svg height="0" width="0">
            <defs>
                <linearGradient
                    id="slider1_range_grad"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"

                >
                    <stop
                        offset="0%"
                        style="stop-color: rgba(75, 168, 46, 0); stop-opacity: 1"
                    />
                    <stop
                        offset="100%"
                        style="stop-color: #419468; stop-opacity: 1"
                    />
                </linearGradient>
            </defs>
        </svg>
        <div class="charging__slider">
            <round-slider
                v-model="chargeTime"
                :max="720"
                :step="30"
                range-color="url(#slider1_range_grad)"
                :read-only="completed"
            ></round-slider>
            <div class="charging__sliderText">
                <div class="m-auto">
                    <div class="d-flex align-items-center">
                        <svg class="ico text-secondary me-2" width="12" height="20"><use xlink:href="#charging"></use></svg> Čas nabíjení
                    </div>
                    <span class="">{{ chargeTimeFormatted }}</span>
                </div>
            </div>
        </div>
        <c-button :disabled="!allSet" @click="openPopup" class="btn btn--green">
            Pokračovat
        </c-button>
        <answer-popup
            v-model="popup"
            :correct="chargeTime === 450"
            @close="complete"
        >
            7 h 30 min
        </answer-popup>
    </div>
</template>

<script>
import stepValue from "@/utils/stepValue";
import {mapGetters} from "vuex";
import CButton from "@/components/CButton";
import nextStep from "@/mixins/nextStep";
import RoundSlider from "@/components/RoundSlider";

import bgImg from "@/assets/images/charging.png";
import AnswerPopup from "@/components/AnswerPopup";

const stepName = 'charging'

export default {
    name: "ChargingView",
    components: {AnswerPopup, RoundSlider, CButton},
    mixins:[nextStep],
    data() {
        return {
            bgImg: bgImg,
            popup: false,
        }
    },
    computed: {
        ...mapGetters(stepName, ['allSet']),
        chargeTime: stepValue(stepName, 'chargeTime'),
        completed: stepValue(stepName, 'completed'),
        chargeTimeFormatted() {
            const duration = {
                    hours: Math.floor(this.chargeTime / 60),
                    minutes: (this.chargeTime % 60).toLocaleString('cs-CZ', {minimumIntegerDigits: 2})
            };
            return `${duration.hours} h ${duration.minutes} min`;
        }
    },
    methods: {
        openPopup() {
            this.completed = true;
            this.popup = true
        },
        complete() {
            this.nextStep();
        }
    }
}
</script>

<style scoped>

</style>
