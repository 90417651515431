<template>
    <div>
        <slot name="label">
            <p class="reach__menuTitle">
                <span>{{ label }}</span>
                <svg class="ico ico--sm"><use xlink:href="#info"></use></svg>
            </p>

        </slot>
        <div class="reach__menuButtons">
            <c-button
            v-for="option in options"
            @click="setValue(option[valueAttribute])"
            :key="option[valueAttribute]"
            :class="{'is-active': value === option[valueAttribute] }"
            class="btn btn--reachGray"
            >
                {{ option[textAttribute] }}
            </c-button>
        </div>

    </div>
</template>

<script>
import CButton from "@/components/CButton";
export default {
    name: "ButtonRadioGroup",
    components: {CButton},
    props: {
        options: {
            type: Array
        },
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        },
        valueAttribute: {
            type: String,
            default: 'value'
        },
        textAttribute: {
            type: String,
            default: 'text'
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        setValue(value) {
            if(this.readonly) {
                return;
            }
            this.value = value;
        }
    }
}
</script>

<style scoped>

</style>
