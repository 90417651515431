<template>
    <div class="colorRadioGroup">
        <slot name="label">
            <span>{{ label }}</span>
        </slot>
        <c-button
            v-for="option in options"
            @click="value = option[valueAttribute]"
            :key="option[valueAttribute]"
            :style="{
                'background-color': option[colorAttribute],
                'border-color': '#A7AEB4',
                'border-size' : '1px'
            }"
            class="colorRadioGroup__item"
        >
           &nbsp;<svg-icon
            v-if="value == option[valueAttribute]"
            :icon="option[valueAttribute] === 'white' ? 'ok-black' : 'ok-white'"
            class="ico m-auto ico--mini"
        ></svg-icon>
        </c-button>
    </div>
</template>

<script>
import CButton from "@/components/CButton";
import SvgIcon from "@/components/SvgIcon";
export default {
    name: "ColorRadioGroup",
    components: {SvgIcon, CButton},
    props: {
        options: {
            type: Array
        },
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        },
        colorAttribute: {
            type: String,
            default: 'color'
        },
        valueAttribute: {
            type: String,
            default: 'value'
        },
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    }
}
</script>

<style scoped>

</style>
