<template>
    <div>
        <header class="header header__map text-dark-green">
            <h1 class="h4">Infrastruktura</h1>
            <p class="perex">
                Označte v mapě 4 stanice, které byste na vašich častých cestách využili.
            </p>
        </header>
        <div style="height: 71vh; width: 100vw;" class="map">
        <l-map
            v-if="markers.length > 0"
            :zoom="zoom"
            :center="centerPoint"
            :options="mapOptions"
            :max-zoom="15"
        >
            <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ></l-tile-layer>
            <l-control position="bottomleft">
                <div class="mapButtonBlock">
                    <c-button class="btn btn--green" :disabled="!allSet" @click="complete">
                        {{ buttonText }}
                    </c-button>
                </div>
            </l-control>
            <l-control-zoom position="topright"></l-control-zoom>
            <marker-cluster
                :options="clusterOptions"
            >
            <l-marker
                v-for="marker in markers"
                :key="marker.id"
                :lat-lng="marker.coords"
                :class="{'markerSelected' : chargingPointsIds.indexOf(marker.id) >= 0}"
                @click="toggleSelect(marker);"
                :icon="marker.icon"
            >
            </l-marker>
            </marker-cluster>
        </l-map>
        </div>
    </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import {LControl, LMap, LMarker, LTileLayer, LControlZoom} from '@vue-leaflet/vue-leaflet';
import {mapGetters} from "vuex";
import CButton from "@/components/CButton";
import stepValue from "@/utils/stepValue";
import nextStep from "@/mixins/nextStep";
import MarkerCluster from "@/components/MarkerCluster";
import * as L from 'leaflet/dist/leaflet-src.esm'
import 'leaflet.markercluster/dist/MarkerCluster.css'

const stepName = "chargingNetwork";

export default {
    name: "ChargingNetworkView",
    components: {
        MarkerCluster,
        CButton,
        LMap, LMarker, LTileLayer, LControl, LControlZoom
    },
    mixins:[nextStep],
    data: () => {
        return {
            zoom: 6,
            centerPoint: [49.7437572, 15.3386383],
            points: [],
            mapOptions: {
                scrollWheelZoom: true,
                zoomControl: false
            },
            clusterOptions: {
                iconCreateFunction:  (cluster) => {
                    const n = cluster.getChildCount();
                    return L.divIcon({ html: n, className: 'mapMarker', iconSize: L.point(25, 25) });
                },
            }
        };
    },
    computed: {
        ...mapGetters(
            'chargingNetwork',
            [
                'chargingPointsIds',
                'allSet',
                'missingPointCount'
            ]
        ),
        completed: stepValue(stepName, 'completed'),
        buttonText() {
            if(this.missingPointCount === 0) {
                return "Pokračovat"
            }
            return "Zbývá " + this.missingPointCount;
        },
        markers() {
            const icon = L.divIcon({
                html: '<svg class="icon"><use xlink:href="#charging"/></svg>',
                className: 'mapMarker mapMarker--icon',
                iconSize: L.point(36, 36)
            });

            return this.points.map((point) => {
                const index = this.chargingPointsIds.indexOf(point.id);
                const selectedIcon = L.divIcon({
                    html: '<svg class="icon"><use xlink:href="#ok"/></svg>' + (index + 1),
                    className: 'mapMarkerSelected',
                    iconSize: L.point(50, 50)
                });
                return {
                    id: point.id,
                    coords: [point.lat, point.lng],
                    icon: index >= 0 ? selectedIcon : icon
                }
            })
        }
    },
    methods: {
        toggleSelect(marker) {
            if(this.completed) {
                return;
            }
            this.$store.dispatch('chargingNetwork/togglePoint', {
                marker: {
                    id: marker.id,
                    coords: marker.coords
                }
            });
        },
        complete() {
            this.completed = true;
            this.nextStep();
        }
    },
    beforeRouteEnter(to, from, next) {
        return next(async (vm) => {
            vm.points = await vm.$store.dispatch('chargingNetwork/getChangingPoints');
        })
    }
}
</script>

<style>
.markerSelected {
    opacity: 0.5;
}

</style>
