<template>
    <div
        v-if="notAllCompleted"
        class="eval"
    >
        <c-button :to="{name: 'venue-plan'}" class="eval__close">
            <svg class="ico mx-auto ico--medium mb-3"><use xlink:href="#close-white"></use></svg>
            Zavřít
        </c-button>
        <div class="eval__content">
            <p class="h1 mb-0">
                Nejdříve poznejte prvních 6 pilířů
            </p>
        </div>
    </div>
    <div v-else class="skodaAndYou">
        <h1 class="skodaAndYou__title">
            ŠKODA ENYAQ iV A...<br>
            <span>{{name}}</span>
        </h1>

        <div class="m-auto" :style="{'pointer-events': completed ? 'none' : false}">
            <div v-if="page === 1" class="textContainer">
                <div class="textContainer__heading">
                    Odpovězte na tři krátké dotazy a my vám ukážeme, který z vozů ŠKODA ENYAQ iV se právě k vám nejvíce hodí.
                </div>
                <div class="text-center p-4">
                    <h2 class="h4 mb-4 pb-2">Co vás nejlépe <br> charakterizuje?</h2>
                    <c-button class="btn" @click="setPerson('businessman')"
                        :class="{'btn--green': person==='businessman', 'btn--borderGreen': person !== 'businessman'}"
                    >
                        Businessman
                    </c-button><br>
                    <c-button class="btn" @click="setPerson('family')"
                        :class="{'btn--green': person==='family', 'btn--borderGreen': person !== 'family'}"
                    >
                        Rodinný typ
                    </c-button>
                </div>
            </div>
            <div v-if="page === 2" class="textContainer text-center">
                <h2 class="h4 mb-4 pb-2">Kde se cítíte nejlépe?</h2>
                <div class="d-flex align-items-center">
                    <c-button @click="setPlace('city')"
                        class="skodaAndYou__btnIcon"
                        :class="{'is-active': place==='city'}"

                    >
                        <svg class="ico m-auto ico--big"><use xlink:href="#skyline"></use></svg>
                        <p class="my-4">Ve městě</p>
                        <svg class="ico m-auto ico--mini" v-if="place==='city'"><use xlink:href="#ok"></use></svg>
                    </c-button><br>
                    <c-button @click="setPlace('nature')"
                        class="skodaAndYou__btnIcon"
                        :class="{'is-active': place==='nature'}"
                    >
                        <svg class="ico m-auto ico--big"><use xlink:href="#nature"></use></svg>
                        <p class="my-4">V přírodě </p>
                        <svg class="ico m-auto ico--mini" v-if="place==='nature'"><use xlink:href="#ok"></use></svg>
                    </c-button>
                </div>
            </div>
            <div v-if="page === 3" class="textContainer text-center">
                <h4 class="h4 mb-4 pb-1">Vyberte barvu</h4>
                <color-radio-group :options="colors" v-model="color"></color-radio-group>
            </div>
        </div>
        <div class="skodaAndYou__steps" v-if="!allSet">
            <c-button @click="page = 1" class="skodaAndYou__step" :class="{'is-active': person}">
                <svg class="ico m-auto" v-if="person"><use xlink:href="#ok"></use></svg>
                <span v-else class="m-auto">1</span>
            </c-button>
            <c-button @click="page = 2" :disabled="!place" class="skodaAndYou__step" :class="{'is-active': place}">
                <svg class="ico m-auto" v-if="place"><use xlink:href="#ok"></use></svg>
                <span v-else class="m-auto">2</span>
            </c-button>
            <c-button @click="page = 3" :disabled="!color" class="skodaAndYou__step" :class="{'is-active': color}">
                <svg class="ico m-auto" v-if="color"><use xlink:href="#ok"></use></svg>
                <span v-else class="m-auto">3</span>
            </c-button>
        </div>
        <div class="text-center" v-else-if="allSet && !completed">
            <c-button :disabled="!allSet" @click="complete" class="btn btn--borderWhite">Pokračovat</c-button>
        </div>
        <div class="text-center" v-else>
            <c-button @click="stepping" class="btn btn--borderWhite">Pokračovat</c-button>
        </div>
    </div>
</template>

<script>
import CButton from "@/components/CButton";
import {mapGetters} from "vuex";
import stepValue from "@/utils/stepValue";
import ColorRadioGroup from "@/components/ColorRadioGroup";
import nextStep from "@/mixins/nextStep";

const stepName = 'skodaAndYou';

export default {
    name: "SkodaAndYouView",
    components: {ColorRadioGroup, CButton},
    mixins: [nextStep],
    data() {
        return {
            page: 1,
            colorData: [
                {
                    value: 'blue',
                    color: '#345089',
                    persons: ['businessman', 'family']
                },
                {
                    value: 'green',
                    color: '#A7C553',
                    persons: ['businessman']
                },
                {
                    value: 'light-green',
                    color: '#CDD8CE',
                    persons: ['family']
                },
                {
                    value: 'white',
                    color: '#F6F6F6',
                    persons: ['businessman', 'family']
                },
                {
                    value: 'light-gray',
                    color: '#B5B5B9',
                    persons: ['businessman', 'family']
                },
                {
                    value: 'black',
                    color: '#1F201F',
                    persons: ['businessman', 'family']
                },
                {
                    value: 'red',
                    color: '#8A241D',
                    persons: ['businessman', 'family']
                },
                {
                    value: 'dark-gray',
                    color: '#919198',
                    persons: ['businessman', 'family']
                },
                {
                    value: 'orange',
                    color: '#C87432',
                    persons: ['businessman']
                }
            ]
        }
    },
    computed: {
        ...mapGetters(stepName, ['allSet']),
        ...mapGetters('users', ['name']),
        person: stepValue(stepName, 'person'),
        place: stepValue(stepName, 'place'),
        color: stepValue(stepName, 'color'),
        completed: stepValue(stepName, 'completed'),
        notAllCompleted() {
            return !(
                this.$store.getters['reach/completed']
                && this.$store.getters['chargingNetwork/completed']
                && this.$store.getters['charging/completed']
                && this.$store.getters['cooperation/completed']
                && this.$store.getters['sustainability/completed']
                && this.$store.getters['vision/completed']
            )
        },
        colors() {
            return this.colorData.filter(color => color.persons.indexOf(this.person) >= 0)
        }
    },
    methods: {
        setPerson(person) {
            this.person = person;
            this.page++;
        },
        setPlace(place) {
            this.place = place;
            this.page++;
        },
        stepping() {
            if(this.page < 3) {
                this.page++;
                return;
            }
            this.$router.push({name: 'selected-model'});
        },
        async complete() {
            this.completed = true;
            await this.$store.dispatch('users/complete');
            await this.$router.push({name: 'selected-model'});
        }
    }
}
</script>

<style scoped>

</style>
