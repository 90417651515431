import axios from "@/plugins/axios";

const defaultState = () => {
    return {
        chargingPoints: [],
        completed: false,
        maxPoints: 4
    };
};

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        chargingPoints: (state) => state.chargingPoints,
        chargingPointsIds: (state) => state.chargingPoints.map((point) => point.id),
        completed: (state) => state.completed,
        allSet: (state) => state.chargingPoints.length === state.maxPoints,
        missingPointCount:  (state) => state.maxPoints - state.chargingPoints.length,
        hasMarker: (state) => (marker)  => state.chargingPoints.some((point) => point.id === marker.id),
    },
    mutations: {
        SET_VALUE(state, {property, value}) {
            state[property] = value;
        },
        REMOVE_POINT(state, {marker}) {
            const index = state.chargingPoints.map((point) => point.id).indexOf(marker.id);
            state.chargingPoints.splice(index, 1);
        },
        ADD_POINT(state, {marker}) {
            state.chargingPoints.push(marker)
        },
        RESET(state) {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        togglePoint(context, {marker}) {
            if(context.getters.hasMarker(marker)) {
                context.commit('REMOVE_POINT', {marker});
            } else {
                if(context.getters.missingPointCount === 0) {
                    return;
                }
                context.commit('ADD_POINT', {marker});
            }
        },
        async getChangingPoints() {
            const response = await axios.get('/charging-points');
            return response.data;
        },
        setValue(context, {property, value}) {
            context.commit('SET_VALUE', {property, value})
        }
    },
};
