// const steps = [
//     'chargingNetwork',
//     'reach',
//     'cooperation',
//     'charging',
//     'sustainability',
//     'vision',
// ];

export default {
    methods: {
        nextStep() {
            // if(
            //     steps
            //         .map((step) => this.$store.getters[step + '/completed'])
            //         .some((completed) => !completed)
            // ) {
            //     this.$router.push({name: 'venue-plan'});
            //     return;
            // }
            // this.$router.push({name: 'skoda-and-you'})

            this.$router.push({name: 'venue-plan'});
        }
    }
}
