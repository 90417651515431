<template>
    <div class="reach">
        <h1 class="h4">Dojezd</h1>
        <p class="perex mb-3">
            Představte si, že z dnešní akce odjedete novým vozem Enyaq Coupé RS iV. Sestavte si pro tuto cestu předpokládanou dojezdovou vzdálenost.
        </p>
        <div class="reach__menu">
            <button-radio-group
            label="Roční období"
            :options="seasonOptions"
            v-model="season"
            :readonly="completed"
            >
            </button-radio-group>
        </div>
        <div class="reach__menu">
            <button-radio-group
            label="Prostředí jízdy"
            :options="drivingEnvironmentOptions"
            v-model="drivingEnvironment"
            :readonly="completed"
            >
            </button-radio-group>
        </div>
        <div class="reach__menu">
            <button-radio-group
            label="Převažující jízdní styl"
            :options="drivingStyleOptions"
            v-model="drivingStyle"
            :readonly="completed"
            >
            </button-radio-group>
        </div>
        <div class="reach__image">
            <img :src="imagetext">
        </div>



        <p class="reach__subPerex">
            <strong>Dojezd</strong>
            {{ reach }}
            km
            <span>                
                Dojezd vozu je počítán pouze
                s řidičem a teplotou v kabině 18° C.
            </span>
        </p>
        <c-button :disabled="!allSet" @click="complete" class="btn btn--green">
            Pokračovat
        </c-button>
    </div>


</template>

<script>
import ButtonRadioGroup from "@/components/ButtonRadioGroup";
import CButton from "@/components/CButton";
import stepValue from "@/utils/stepValue";
import {mapGetters} from "vuex";
import enyaqReach from "@/assets/enyaq-reach.json";
import nextStep from "@/mixins/nextStep";
import img from "@/assets/images/enyaq-rsiv-text.png";

const stepName = 'reach';

export default {
    name: "ReachView",
    components: {CButton, ButtonRadioGroup},
    mixins: [nextStep],
    data: () => {
        return {
            imagetext: img,
            seasonOptions: [
                {text: 'Léto', value: 'summer'},
                {text: 'Jaro / Podzim', value: 'middle'},
                {text: 'Zima', value: 'winter'}
            ],
            drivingEnvironmentOptions: [
                {text: 'Město', value: 'city'},
                {text: 'Mimo město', value: 'outCity'},
                {text: 'Dálnice', value: 'highway'}
            ],
            drivingStyleOptions: [
                {text: 'Eko', value: 'eco'},
                {text: 'Normální', value: 'normal'},
                {text: 'Sportovní', value: 'sport'}
            ],
            reachData: enyaqReach || []
        }
    },
    computed: {
        ...mapGetters('reach', ['allSet']),
        season: stepValue(stepName, 'season'),
        drivingStyle: stepValue(stepName, 'drivingStyle'),
        drivingEnvironment: stepValue(stepName, 'drivingEnvironment'),
        completed: stepValue(stepName, 'completed'),
        reach() {
            const _this = this;
            const reach = this.reachData.find(
                (item) => item.season === _this.season
                    && item.drivingStyle === _this.drivingStyle
                    && item.drivingEnvironment === _this.drivingEnvironment
            )
            if(!reach) {
                return '?'
            }
            return reach.reach;
        }
    },
    methods: {
       complete() {
           this.completed = true;
           this.nextStep();
       }
    },
}
</script>

<style scoped>

</style>
