<template>
    <label class="checkbox">
        <input ref="input" v-model="innerValue" type="checkbox" :required="required">
        <slot name="label">
            <span v-if="label">{{ label }}</span>
        </slot>
    </label>
</template>

<script>
export default {
    name: "CCheckbox",
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
    },
    emits: ['update:modelValue'],
    computed: {
        innerValue: {
            get() {
                return this.modelValue
            },
            async set(value) {
                await this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        click() {
            var evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            });
            // If cancelled, don't dispatch our event
            !this.$refs.input.dispatchEvent(evt);
        }
    }
}
</script>

<style scoped>

</style>
