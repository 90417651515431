<template>
    <nav :class="{'is-open' : isOpen}" class="nav">
        <div class="nav__logo">
            <img :src="skodaLogo">
        </div>
        <div v-if="isAuthorized" class="nav__count" @click.prevent="isOpen = !isOpen">
            <div class="nav__countText">
                <span>{{countCompleted}}</span>/{{countTotal}}
            </div>
        </div>
        <div class="nav__items">
            <div class="nav__itemsHeader">
                <div class="nav__logo">
                <img :src="skodaLogo">
                </div>
                <div class="nav__close" @click.prevent="isOpen = !isOpen">
                </div>
            </div>
            <div class="nav__itemsCategory">
                <main-menu-item
                v-for="link in menuItems"
                :key="link.to.name"
                :to="link.to"
                :text="link.text"
                :completed="link.completed"
                :icon="link.icon"
                @click="isOpen = false"
                >
                </main-menu-item>
            </div>
            <p class="nav__itemsPerex">
                *Úkoly můžete řešit v libovolném pořadí
            </p>
        </div>
    </nav>
</template>

<script>
import MainMenuItem from "@/components/MainMenuItem";
import img from "@/assets/images/skoda-logo.png";
import {mapGetters} from "vuex";


export default {
    name: "MainMenu",
    components: {MainMenuItem},
    data() {
       return {
           skodaLogo: img,
           isOpen: false,
           links: [
               {
                   text: "Venue plan",
                   to: {name: 'venue-plan'},
                   icon: "location"
               },
               {
                   text: "Infrastruktura",
                   to: {name: 'charging-network'},
                   module: "chargingNetwork",
                   icon: "pump"
               },
               {
                   text: "Dojezd",
                   to: {name: 'reach'},
                   module: 'reach',
                   icon: "reach"
               },
               {
                   text: "Spolupráce",
                   to: {name: 'cooperation'},
                   module: "cooperation",
                   icon: "cooperation"
               },
               {
                   text: "Nabíjení",
                   to: {name: 'charging'},
                   module: "charging",
                   icon: "charging"
               },
               {
                   text: "Udržitelnost",
                   to: {name: 'sustainability'},
                   module: 'sustainability',
                   icon: "sustainability"
               },
               {
                   text: "Vision 7S",
                   to: {name: 'vision-7s'},
                   module: 'vision',
                   icon: "vision7s"
               },
               {
                   text: "Škoda a vy",
                   to: {name: 'skoda-and-you'},
                   module: 'skodaAndYou',
                   icon: "skoda"
               }
           ],
       };
    },
    computed: {
        ...mapGetters('users', ['isAuthorized']),
        countCompleted() {
            return this.menuItems.filter(link => link.completed).length;
        },
        countTotal() {
            return this.menuItems.filter(link => Object.hasOwn(link, 'module')).length;
        },
        menuItems()  {
            const _this = this;
            let countCompleted = 0;
            return this.links
                .map((link) => {
                    let completed = false;
                    if (Object.hasOwn(link, 'module')) {
                        completed = _this.$store.getters[link.module + '/completed']
                    }
                    if(completed) {
                        countCompleted++;
                    }
                    return {
                        ...link,
                        completed
                    }
                })
                .filter((link) => {
                    if (!Object.hasOwn(link, 'ifCompleted')) {
                        return true;
                    }
                    return countCompleted >= link.ifCompleted;
                })
        }
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
