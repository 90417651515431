<template>
    <svg-sprite/>
    <main-menu/>
    <router-view/>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import SvgSprite from "@/components/SvgSprite";

export default {
    components: {SvgSprite, MainMenu}
}
</script>

<style lang="scss">

</style>
