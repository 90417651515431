<template>
    <div style="display: none;">
        <slot v-if="ready"></slot>
    </div>
</template>

<script>
import * as L from 'leaflet/dist/leaflet-src.esm'
import { MarkerClusterGroup } from 'leaflet.markercluster'
import {provide, ref, watch} from "vue";

const capitalizeFirstLetter = (string) => {
    if (!string || typeof string.charAt !== "function") {
        return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const propsBinder = (methods, leafletElement, props) => {
    for (const key in props) {
        const setMethodName = "set" + capitalizeFirstLetter(key);
        if (methods[setMethodName]) {
            watch(
                () => props[key],
                (newVal, oldVal) => {
                    methods[setMethodName](newVal, oldVal);
                }
            );
        } else if (leafletElement[setMethodName]) {
            watch(
                () => props[key],
                (newVal) => {
                    leafletElement[setMethodName](newVal);
                }
            );
        }
    }
};

const provideLeafletWrapper = (methodName) => {
    const wrapped = ref(() =>
        console.warn(`Method ${methodName} has been invoked without being replaced`)
    );
    const wrapper = (...args) => wrapped.value(...args);
    // eslint-disable-next-line vue/no-ref-as-operand
    wrapper.wrapped = wrapped;
    provide(methodName, wrapper);

    return wrapper;
};

/**
 * Change the function that will be executed when an injected Leaflet wrapper
 * is invoked.
 *
 * @param {*} wrapper Provided wrapper whose wrapped function is to be updated
 * @param {function} leafletMethod New method to be wrapped by the wrapper
 */
const updateLeafletWrapper = (wrapper, leafletMethod) =>
    (wrapper.wrapped.value = leafletMethod);


const props = {
    options: {
        type: Object,
        default() { return {}; },
    },
};
export default {
    props,
    data() {
        return {
            ready: false
        };
    },
    inject: {
        parentAddLayer: {
            from: 'addLayer'
        },
        parentRemoveLayer: {
            from: 'removeLayer'
        }
    },
    mounted() {
        const addLayer = provideLeafletWrapper("addLayer");
        const removeLayer = provideLeafletWrapper("removeLayer");
        updateLeafletWrapper(addLayer, this.addLayer);
        updateLeafletWrapper(removeLayer, this.removeLayer);
        const listeners = {}
        const onRE = /^on[^a-z]/
        for(const key in this.$attrs) {
            if(onRE.test(key)) {
                listeners[key] = this.$attrs[key];
            }
        }
        this.leafletObject = new MarkerClusterGroup(this.options);
        L.DomEvent.on(this.leafletObject, listeners); //this.$attrs / this.$listeners
        propsBinder(this, this.leafletObject, props);
        this.ready = true;
        this.parentAddLayer(this);
        this.$nextTick(() => {
            this.$emit('ready', this.leafletObject);
        });
    },
    beforeUnmount() {
        this.parentRemoveLayer(this);
    },
    methods: {
        addLayer(layer) {
            this.leafletObject.addLayer(layer.leafletObject);
        },
        removeLayer(layer) {
            this.leafletObject.removeLayer(layer.leafletObject);
        }
    }
};
</script>
