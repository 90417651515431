import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/scss/style.scss"
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import * as L from 'leaflet/dist/leaflet-src.esm'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

router.beforeEach(async (to, from, next) => {
    const status = await store.dispatch("users/checkAuthorization");
    /**
     * If logged
     */
    if (status) {
        if (to.name === "welcome") {
            return next({ name: "venue-plan" });
        }
        return next();
    }

    /**
     * Force redirect to login if not logged for authenticated routes
     */
    if (to.meta.authenticated) {
        return next({ name: "welcome" });
    }

    next();
});
window.L = L;
const app = createApp(App).use(store).use(router).use(VueTelInput);

Sentry.init({
    app,
    dsn: "https://dfcdfb1251e943efbece950a5e4670d7@sentry.olc.cz/100",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

app.mount('#app');
