<template>
    <div>
        <h1>Pravidla soutěže</h1>
    </div>
</template>

<script>
export default {
    name: "GameRulesView"
}
</script>

<style scoped>

</style>
