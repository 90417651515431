import axios from "axios";

const location = window.location;
const baseURL = `${location.protocol}//${location.host}/api`;

const client = axios.create({
    baseURL,
    headers: { "X-Requested-With": "XMLHttpRequest" },
    withCredentials: true,
});

client.interceptors.request.use((config) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
        return config;
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export default client;
