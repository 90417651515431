<template>
    <router-link
        :to="to"
        class="nav__itemsLink"
        :class="{
            'is-active': active
        }"
    >
        <svg-icon  v-if="icon" :icon="icon"/>
        {{text}}
        <svg-icon  v-if="completed" icon="check" class="is-completed"/>
    </router-link>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
export default {
    name: "MainMenuItem",
    components: {SvgIcon},
    props: {
        icon: {
            type: String
        },
        to: {
            type: Object
        },
        completed: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        }
    },
    computed: {
        active() {
            return this.$route.name === this.to.name;
        },
    }
}
</script>

<style scoped>
</style>
