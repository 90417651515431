<template>
    <div class="world">
        <div class="world__container">
            <h1 class="world__title">Vítejte ve světě</h1>
            <p class="welcome__subSubTitle">
                eMobility
            </p>
            <p class="world__perex">Poznejte všechny pilíře*, které ji tvoří.</p>
            <div class="world__icons">
                <world-icon
                    style="position: absolute; left: 0%; top:72%;"
                    icon="pump"
                    :to="{name: 'charging-network'}"
                    :active="chargingNetworkActive"
                ></world-icon>
                <world-icon
                    style="position: absolute; left: 0%; top:8%;"
                    icon="skoda"
                    :to="{name: 'skoda-and-you'}"
                    :active="skodaAndYouActive"
                    :class="{ 'is-disabled' : !sixCompleted }"
                ></world-icon>

                <world-icon
                    style="position: absolute; left: 0%; top:40%;"
                    icon="cooperation"
                    :to="{name: 'cooperation'}"
                    :active="cooperationActive"
                ></world-icon>
                <world-icon
                    style="position: absolute; left: calc(50% - 2.5rem); top:77%;"
                    icon="vision7s"
                    :to="{name: 'vision-7s'}"
                    :active="vision7SActive"
                ></world-icon>
                <world-icon
                    style="position: absolute; left: calc(50% - 2.5rem); top:12%;"
                    icon="sustainability"
                    :to="{name: 'sustainability'}"
                    :active="sustainabilityActive"
                ></world-icon>
                <world-icon
                    style="position: absolute; right: 0%; top:40%;"
                    icon="charging"
                    :to="{name: 'charging'}"
                    :active="chargingActive"
                ></world-icon>
                <world-icon
                    style="position: absolute; right: 0%; top:72%;"
                    icon="reach"
                    :to="{name: 'reach'}"
                    :active="reachActive"
                ></world-icon>

            </div>
            <p class="world__subPerex">*Úkoly můžete řešit v libovolném pořadí</p>
        </div>
        <div class="world__background">
            <img :src="worldbg">
        </div>
    </div>
</template>

<script>
import img from "@/assets/images/emobility-font.png";
import bg from "@/assets/images/world-bg.png";
import WorldIcon from "@/components/WorldIcon";

export default {
    name: "VenuePlanView",
    components: {WorldIcon},
    data() {
       return {
        emobilityfont: img,
        worldbg: bg
        }
    },
    computed: {
        chargingNetworkActive() {
            return this.$store.getters['chargingNetwork/completed']
        },
        chargingActive() {
            return this.$store.getters['charging/completed']
        },
        reachActive() {
            return this.$store.getters['reach/completed']
        },
        skodaAndYouActive() {
            return this.$store.getters['skodaAndYou/completed']
        },
        sustainabilityActive() {
            return this.$store.getters['sustainability/completed']
        },
        vision7SActive() {
            return this.$store.getters['vision/completed']
        },
        cooperationActive() {
            return this.$store.getters['cooperation/completed']
        },
        sixCompleted() {
            return this.vision7SActive
                && this.cooperationActive
                && this.chargingActive
                && this.chargingNetworkActive
                && this.reachActive
                && this.sustainabilityActive
        }
    }
}

</script>

<style>

</style>
