<template>
    <div class="phoneInput">
        <span v-if="label">{{label}}</span>
        <vue-tel-input
            styleClasses="phoneInput__input"
            v-model="innerValue"
            default-country="CZ"
            :input-options="inputOptions"
            @validate="validate"
            @focus="focus"
            @blur="blur"
        ></vue-tel-input>
    </div>
</template>

<script>
export default {
    name: "PhoneInput",
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            phoneObject: null
        }
    },
    computed: {
        innerValue: {
            get() {
                return this.modelValue
            },
            async set(value) {
                await this.$emit('update:modelValue', value);
            }
        },
        inputOptions() {
            return {
                required: this.required,
                showDialCode: true
            };
        }
    },
    methods: {
        validate(data) {
            this.phoneObject = data;
        },
        focus() {
            this.$emit('error', '');
            this.$emit('focus');
        },
        blur() {
            if(this.phoneObject && !this.phoneObject.valid) {
                this.$emit('error', 'Zadané telefoní číslo není validní');
            }
            this.$emit('blur');
        }
    }
}
</script>

<style>

</style>
