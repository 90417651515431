<template>
    <component
        :style="{
           'pointer-events': (disabled ? 'none' : 'inherit')
        }"
        :is="tag"
        :tag="routerLinkTag"
        :to="routerTo"
        :disabled="disabled"
    >
        <slot></slot>
    </component>
</template>

<script>
export default {
    name: "CButton",
    props: {
        tagName: {
            type: String,
            default: ''
        },
        to: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        tag() {
            if(this.tagName) {
                return this.tagName;
            }
            if(this.to) {
                return 'router-link'
            }
            return 'button';
        },
        routerTo() {
            if(this.tag === 'router-link') {
                return this.to;
            }
            return false;
        },
        routerLinkTag() {
            if(this.tag === 'router-link') {
                return 'a'
            }
            return false;
        }
    }
}
</script>

<style scoped>

</style>
