const defaultState = () => {
    return {
        highlights: [],
        completed: false,
        maxHighlights: 7
    };
};

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        highlights: (state) => state.highlights,
        completed: (state) => state.completed,
        allSet: (state) => state.highlights.length === state.maxHighlights,
        hasHighlight: (state) => (highlight)  => state.highlights.some((point) => point === highlight),
    },
    mutations: {
        SET_VALUE(state, {property, value}) {
            state[property] = value;
        },
        ADD_HIGHLIGHT(state, {id}) {
            state.highlights.push(id)
        },
        RESET(state) {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        addHighlight(context, {id}) {
            if(context.getters.hasHighlight(id)) {
                return;
            }
            context.commit('ADD_HIGHLIGHT', {id});
        },
        setValue(context, {property, value}) {
            context.commit('SET_VALUE', {property, value})
        }
    },
};
