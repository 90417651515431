<template>
    <div>
        <slider
            class="slider-simple"
            :class="{
                'is-readonly': readonly
            }"
            v-model="value"
            :min="min"
            :max="max"
            :step="step"
            :options="options"
            @update="update"
            :lazy="false"
        ></slider>
        <div class="coop__numbers">
            <span class="coop__zero">{{min}}</span>
            <span class="coop__max">{{max}}</span>
        </div>
    </div>
</template>

<script>
import "@/assets/scss/style.scss"
import Slider from "@vueform/slider";

export default {
    name: "SimpleSlider",
    components: {
        Slider
    },
    props: {
        modelValue: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    data: () => {
        return {
            options: {}
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                if(this.readonly) {
                    return;
                }
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        update(value) {
            this.value = value;
        }
    }
}
</script>

<style scoped lang="scss">
/* https://github.com/vueform/slider */
.slider-thermal {
    --slider-bg: lime;
    --slider-connect-bg: transparent;
    --slider-handle-ring-color: #EF444430;
}
</style>
