<template>
    <div
        v-if="value"
        class="eval"
        :class="{
            'eval--success': correct,
         }"
    >
        <c-button @click="value = false;" class="eval__close">
            <svg class="ico mx-auto ico--medium mb-3"><use xlink:href="#x"></use></svg>
            Zavřít
        </c-button>
        <div class="eval__content">
            <p class="eval__title">
                {{ correct ? 'SPRÁVNĚ' : 'Těsně vedle' }}
                <span v-if="!correct">
                    správná odpověď je
                </span>
            </p>
            <div class="eval__text" v-if="!correct">
                <slot>

                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import CButton from "@/components/CButton";

export default {
    name: "AnswerPopup",
    components: {CButton},
    props: {
        modelValue: {
            type: Boolean
        },
        correct: {
            type: Boolean,
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
                this.$emit(value ? 'open' : 'close')
            }
        }
    }
}
</script>

<style scoped>

</style>
