<template>
    <div class="welcome">
        <div class="welcome__container">
            <h1 class="welcome__title">Poznejte</h1>
            <p class="welcome__subTitle">7 divů</p>
            <p class="welcome__subSubTitle">
                eMobility
            </p>
            <p class="welcome__subSubSubTitle">a vyhrajte</p>
        </div>
        <form @submit.prevent="sign" class="welcome__form">
            <c-text-input v-model="name" label="Jméno" class="input" required></c-text-input>
            <c-text-input
                v-model="email"
                label="E-mail"
                class="input"
                type="email"
                :class="{'text-error': (this.sendError && this.sendError.field === 'email')}"
                required
            ></c-text-input>
            <phone-input
                v-model="phone"
                label="Telefon"
                :class="{'text-error': phoneError || (this.sendError && this.sendError.field === 'phone')}"
                @error="errorEvent"
                required
            ></phone-input>
            <span class="d-block mb-4 fs-14">
                Odesláním formuláře potvrzujete, že jste se seznámil s Pravidly soutěže a souhlasíte že budeme Vaše osobní údaje zpracovávat za účelem Pořádání soutěže a hlasování v soutěži. Více informací, včetně Vašich práv,
                je uvedeno <a href="https://www.skoda-auto.cz/o-spolecnosti/pravidla-soutezi" target="_blank" class="text-blue">zde.</a>
            </span>
            <c-checkbox v-model="gdpr">
                <template v-slot:label>
                    <span>
                        Tímto uděluji <a href="https://www.skoda-auto.cz/sluzby/memorandum-realizace-marketingovych-aktivit" target="_blank" class="text-blue">souhlas se zpracováním svých osobních údajů</a> za účelem oslovování s nabídkou produktů,
                        <span v-if="!showMore" class="text-blue" role="button" @click="showMore = !showMore">... zobrazit více</span>
                        <span v-if="showMore"> služeb a výzkumu trhu značky Škoda Auto, včetně informování o akcích, soutěžích, novinkách a zasílání přání k svátkům, a to i na základě informací o používání produktů a služeb. Za účelem obohacení zákaznických dat může Škoda Auto také předávat mé osobní údaje třetím stranám jako je Volkswagen Financial Services AG, preferovaný dealer a importér odpovědný za daný trh.
                            <span class="text-blue" role="button" @click="showMore = !showMore"> skrýt</span>
                        </span>
                    </span>
                </template>
            </c-checkbox>
            <p class="text-error-input mb-4" v-if="error">
                <span class="text-error-active" v-html="error"></span>
            </p>
            <c-button type="submit" class="btn btn--green">
                Pokračovat
            </c-button>
        </form>
    </div>
</template>

<script>
import CTextInput from "@/components/CTextInput";
import CButton from "@/components/CButton";
import img from "@/assets/images/emobility-font.png";
import PhoneInput from "@/components/PhoneInput";
import CCheckbox from "@/components/CCheckbox.vue";

export default {
    name: "WelcomeView",
    components: {PhoneInput, CButton, CTextInput, CCheckbox},
    data: () => {
        return {
            emobilityfont: img,
            email: '',
            name: '',
            phone: '',
            phoneError: '',
            sendError: null,
            gdpr: false,
            showMore: false
        }
    },
    computed: {
        error() {
            return [this.phoneError, this.sendError ? this.sendError.message : null].filter(error => !!error).join('<br>');
        }
    },
    methods: {
        async sign() {
            this.sendError = '';
            if(this.phoneError) {
                return;
            }
            const error = await this.$store.dispatch('users/signUser', {
                name: this.name,
                email: this.email,
                phone: this.phone,
                gdpr: this.gdpr,
                rules: true,
            });
            if(!error) {
                this.$router.push({name: 'venue-plan'});
                return;
            }
            this.sendError = error;
        },
        errorEvent(message) {
            this.phoneError = message;
        }
    }
}
</script>

<style scoped>

</style>
